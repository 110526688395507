import { useEffect } from 'react';
import { FormInstance, Input, Form } from 'antd';
import { ChangePassForm } from '../../../../api/users-api/classes';

interface Props {
  onFormInstanceReady: (instance: FormInstance<ChangePassForm>) => void;
  user_id: string;
  user_email: string;
}

const CreateForm: React.FC<Props> = ({ onFormInstanceReady, user_id, user_email }) => {
  const [form] = Form.useForm();

  form.setFieldValue('user_id', user_id);
  form.setFieldValue('email', user_email);

  useEffect(() => {
    onFormInstanceReady(form);
  }, [form, onFormInstanceReady]);

  return (
    <Form layout="vertical" form={form} name="form_in_modal">
      <Form.Item name="user_id" label="Логин" rules={[{ required: true, message: 'Введите логин' }]}>
        <Input value={user_id} disabled={true} />
      </Form.Item>
      <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Введите email' }]}>
        <Input value={user_email} disabled={true} />
      </Form.Item>
      <Form.Item name="password" label="New Password" rules={[{ required: true, message: 'Введите пароль' }]}>
        <Input />
      </Form.Item>
    </Form>
  );
};

export default CreateForm;
