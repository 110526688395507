import { API } from '..';
import { getToken } from '../../context/AuthContextProvider';
import { BodyPatchAPIKeys, BodyPostAPIKeys, getAPI_KEYS_DataParams, GetAPI_KEYS_Response } from './classes';

const PATHS = {
  API_KEYS: '/api/api-keys',
};

export const APIKEYS_API = {
  getAPIKeys: async (props: getAPI_KEYS_DataParams) => {
    const token = getToken();
    return await API.get<GetAPI_KEYS_Response>({
      url: PATHS.API_KEYS,
      params: { ...props },
      headers: {
        'x-access-token': token,
      },
    });
  },

  postAPIKey: async (props: BodyPostAPIKeys) => {    
    const withEditedDataType: BodyPostAPIKeys = {
      ...props,
      affiliate_id: props.affiliate_id !== '' ? props.affiliate_id : null,
      commission_percent: Number(props.commission_percent),
    };

    const token = getToken();
    return await API.post({
      url: PATHS.API_KEYS,
      data: { ...withEditedDataType },
      headers: {
        'x-access-token': token,
      },
    });
  },

  patchAPIKeys: async (props: BodyPatchAPIKeys) => {
    const withEditedDataType: BodyPatchAPIKeys = {
      ...props,
      affiliate_id: props.affiliate_id !== '' ? props.affiliate_id : null,
      commission_percent: Number(props.commission_percent),
    };
    const token = getToken();
    return await API.patch({
      url: PATHS.API_KEYS,
      data: { ...withEditedDataType },
      headers: {
        'x-access-token': token,
      },
    });
  },
};
