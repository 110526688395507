import { useState } from 'react';
import { Modal, type FormInstance } from 'antd';
import CreateForm from './form';
import {  ChangePassForm } from '../../../api/users-api/classes';

interface Props {
  open: boolean;
  onCreate: (values: ChangePassForm) => void;
  onCancel: () => void;
  user_id: string;
  user_email: string;
}

const ChangePassModal: React.FC<Props> = ({ open, onCreate, onCancel, user_email, user_id }) => {
  const [formInstance, setFormInstance] = useState<FormInstance>();
  return (
    <Modal
      open={open}
      title="Изменить пароль"
      okText="Change"
      cancelText="Cancel"
      okButtonProps={{ autoFocus: true }}
      onCancel={onCancel}
      destroyOnClose
      onOk={async () => {
        try {
          const values = await formInstance?.validateFields();
          formInstance?.resetFields();
          onCreate(values);
        } catch (error) {
          console.log('Failed:', error);
        }
      }}
    >
      <CreateForm
        onFormInstanceReady={(instance) => {
          setFormInstance(instance);
        }}
        user_email={user_email}
        user_id={user_id}
      />
    </Modal>
  );
};

export default ChangePassModal;
