import { makeAutoObservable } from 'mobx';

import { ClearingDataType } from '../../api/clearing-api/classes';
import { ClearingAPI } from '../../api/clearing-api/api';
import { TSelectDebounceValue } from '../../common/components/SelectDebounceFilter';
import { TProviders, TRateTypes } from '../../common/types';
import { dateMounthAgo, dateTodayEnd, FilteringDateType } from '../../common/components/DateFilters/DateFilterTest';
import { getOneSelectedValue } from '../../common/helpers';

export const actionTypes = [
  'searchValue',
  'coinFromId',
  'coinToId',
  'networkFromId',
  'networkToId',
  'rateType',
  'providerName',
  'filteringDate',
] as const;
export type IActionTypes = (typeof actionTypes)[number];

export type ActionValueTypes = {
  inputText?: string;
  providerName?: TProviders;
  rateType?: TRateTypes;
  filteringDate?: FilteringDateType;
  selectDebounceValue?: TSelectDebounceValue[];
};

type ActionType = {
  type: IActionTypes;
  value: ActionValueTypes;
};

export type ClearingFiltersType = {
  coinFromId: TSelectDebounceValue[];
  coinToId: TSelectDebounceValue[];
  networkFromId: TSelectDebounceValue[];
  networkToId: TSelectDebounceValue[];
  dateTo: string;
  dateFrom: string;
  rateType: TRateTypes | undefined;
  providerName: TProviders | undefined;
};

class ClearingVM {
  data: ClearingDataType[] = [];

  filters: ClearingFiltersType = {
    coinFromId: [],
    coinToId: [],
    networkFromId: [],
    networkToId: [],
    providerName: undefined,
    rateType: undefined,
    dateTo: `${dateTodayEnd}`,
    dateFrom: `${dateMounthAgo}`,
  };

  getClearingData = async () => {
    let props = {
      ...this.filters,
      coinFromId: getOneSelectedValue(this.filters.coinFromId),
      coinToId: getOneSelectedValue(this.filters.coinToId),
      networkFromId: getOneSelectedValue(this.filters.networkFromId),
      networkToId: getOneSelectedValue(this.filters.networkToId),
      dateFrom: new Date(this.filters.dateFrom).toISOString() ,
      dateTo: new Date(this.filters.dateTo).toISOString(),
    };

    const res = await ClearingAPI.getClearingData(props);
    if (res.status === 200) {
      if (res.data) {
        this.data = res.data.statsList;
      }
    }
  };

  selectFilters = (action: ActionType) => {
    switch (action.type) {
      case 'coinFromId':
      case 'coinToId':
      case 'networkFromId':
      case 'networkToId': {
        const value = action.value?.selectDebounceValue;
        this.filters = { ...this.filters, [action.type]: value };
        break;
      }
      case 'providerName': {
        const value = action.value?.providerName;
        this.filters = { ...this.filters, providerName: value };
        break;
      }
      case 'filteringDate': {
        const date = action.value?.filteringDate;
        this.filters = { ...this.filters, ...date };
        break;
      }
      default: {
        throw Error('Unknown action: ' + action.type);
      }
    }
    this.getClearingData();
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export default ClearingVM;
