import { makeAutoObservable } from 'mobx';
import { ClaimDataType, ClaimOneDataType, postAddCommentToClaimProps } from '../../api/exchanges-api/classes';
import { ExchangesAPI } from '../../api/exchanges-api/api';
import { TSelectDebounceValue } from '../../common/components/SelectDebounceFilter';
import { TClaimStatus, TProviders } from '../../common/types';
import { dateTodayEnd, dateMounthAgo } from '../../common/components/DateFilters/DateFilter';
import { FilteringDateType } from '../../common/components/DateFilters/DateFilterTest';
import { getOneSelectedValue } from '../../common/helpers';
import { ClearingFiltersType } from '../clearing/vm';

export const actionTypes = [
  'searchValue',
  'coinId',
  'coinFromId',
  'coinToId',
  'networkId',
  'networkFromId',
  'networkToId',
  'status',
  'providerName',
  'fromClearing',
  'filteringDate',
  'addressFrom',
  'addressTo',
] as const;
export type IActionTypes = (typeof actionTypes)[number];

export type FiltersType = {
  coinId: TSelectDebounceValue[];
  coinFromId: TSelectDebounceValue[];
  coinToId: TSelectDebounceValue[];
  networkId: TSelectDebounceValue[];
  networkFromId: TSelectDebounceValue[];
  networkToId: TSelectDebounceValue[];
  dateTo: string | undefined;
  dateFrom: string | undefined;
  searchValue: string | undefined;
  status: TClaimStatus | undefined;
  providerName: TProviders | undefined;
  addressTo: string | undefined;
  addressFrom: string | undefined;
};

export type ActionValueTypes = {
  inputText?: string;
  providerName?: TProviders;
  claimStatus?: TClaimStatus;
  clearingFilters?: ClearingFiltersType;
  filteringDate?: FilteringDateType;
  selectDebounceValue?: TSelectDebounceValue[];
};

type ActionType = {
  type: IActionTypes;
  value: ActionValueTypes;
};

class ExchangesVM {
  filters: FiltersType = {
    dateTo: `${dateTodayEnd}`,
    dateFrom: `${dateMounthAgo}`,
    searchValue: undefined,
    coinId: [],
    coinFromId: [],
    coinToId: [],
    networkId: [],
    networkFromId: [],
    networkToId: [],

    status: undefined,
    providerName: undefined,
    addressTo: undefined,
    addressFrom: undefined,
  };
  pageInfo = {
    page: 1,
    perPage: 50,
  };
  lastTimeUpdate = '';
  isFetchingData = false;
  exchanges: ClaimDataType[] = [];
  count: number = 0;
  responseGetOneClaimDataField = '';
  oneClaimData: ClaimOneDataType = {} as ClaimOneDataType;

  setPageInfo = (pageInfoData: any) => {
    this.pageInfo = pageInfoData;
    this.getExchanges();
  };

  getExchanges = async () => {
    this.isFetchingData = true;

    let props = {
      ...this.pageInfo,
      ...this.filters,
      coinId: getOneSelectedValue(this.filters.coinId),
      coinFromId: getOneSelectedValue(this.filters.coinFromId),
      coinToId: getOneSelectedValue(this.filters.coinToId),
      networkId: getOneSelectedValue(this.filters.networkId),
      networkFromId: getOneSelectedValue(this.filters.networkFromId),
      networkToId: getOneSelectedValue(this.filters.networkToId),
      dateFrom: this.filters.dateFrom ? new Date(this.filters.dateFrom).toISOString() : undefined,
      dateTo: this.filters.dateTo ? new Date(this.filters.dateTo).toISOString() : undefined,
    };

    const res = await ExchangesAPI.getExchanges(props);
    if (res.status === 200) {
      if (res.data) {
        this.exchanges = res.data.dataList;
        this.count = res.data.count;
        this.isFetchingData = false;
        this.lastTimeUpdate = new Date().toLocaleTimeString();
      }
    }
    return res;
  };

  getOneExchange = async (exchangeID: string) => {
    const res = await ExchangesAPI.getOneExchange(exchangeID);
    if (res.status === 200) {
      if (res.data) {
        this.oneClaimData = res.data.claimOne;
        this.responseGetOneClaimDataField = JSON.stringify(res.data.claimOne, null, 2);
      }
    }
  };

  addComment = async (props: postAddCommentToClaimProps) => {
    const res = await ExchangesAPI.addCommentToClaim(props);
    if (res.isSuccess) {
      await this.getOneExchange(props.claimId);
    } else {
      return Error(res.errorData);
    }
  };

  selectFilters = (action: ActionType) => {
    switch (action.type) {
      case 'searchValue': {
        const value = action.value?.inputText && action.value.inputText !== '' ? action.value.inputText : undefined;
        this.filters = { ...this.filters, searchValue: value };
        break;
      }
      case 'coinId':
      case 'coinFromId':
      case 'coinToId':
      case 'networkId':
      case 'networkFromId':
      case 'networkToId': {
        const value = action.value?.selectDebounceValue;
        this.filters = { ...this.filters, [action.type]: value };
        break;
      }
      case 'status': {
        const value = action.value?.claimStatus;
        this.filters = { ...this.filters, status: value };
        break;
      }
      case 'providerName': {
        const value = action.value?.providerName;
        this.filters = { ...this.filters, providerName: value };
        break;
      }
      case 'filteringDate': {
        const date = action.value?.filteringDate;
        this.filters = { ...this.filters, ...date };
        break;
      }
      case 'fromClearing': {
        const value = action.value?.clearingFilters;
        if (value) {
          this.filters = {
            ...this.filters,
            ...value,
          };
          break;
        }
        this.filters = { ...this.filters };
        break;
      }
      case 'addressTo': {
        const value = action.value?.inputText && action.value.inputText !== '' ? action.value.inputText : undefined;
        this.filters = { ...this.filters, addressTo: value };
        break;
      }
      case 'addressFrom': {
        const value = action.value?.inputText && action.value.inputText !== '' ? action.value.inputText : undefined;
        this.filters = { ...this.filters, addressFrom: value };
        break;
      }
      default: {
        throw Error('Unknown action: ' + action.type);
      }
    }
    this.getExchanges();
  };

  constructor() {
    makeAutoObservable(this, {});
  }
}

export default ExchangesVM;
