import { makeAutoObservable } from 'mobx';

import { Referals_API } from '../../api/referals_api/api';
import { BodyPatchReferals, BodyPostReferals, GetReferalsParams, ReferalCode } from '../../api/referals_api/classes';

class Referal_Codes_VM {
  count: number = 0;
  data: ReferalCode[] = [];

  getReferalCodes = async (props: GetReferalsParams) => {
    const res = await Referals_API.getReferals(props);
    if (res.isSuccess) {
      if (res.data) {
        this.data = res.data.dataList;
        this.count = res.data.count;
      }
    }
  };

  postCode = async (props: BodyPostReferals) => {
    const res = await Referals_API.postReferalCode(props);
    if (res.isSuccess) {
      if (res.data) {
        this.data = res.data.dataList;
        this.count = res.data.count;
      }
    }
  };

  patchReferalCode = async (props: BodyPatchReferals) => {
    const res = await Referals_API.patchReferals(props);
    if (res.isSuccess) {
      if (res.data) {
        this.data = res.data.dataList;
        this.count = res.data.count;
      }
    }
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export default Referal_Codes_VM;
