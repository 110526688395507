import { makeAutoObservable } from 'mobx';

import { GetReferalsParams } from '../../api/referals_api/classes';
import { BodyPatchUser, BodyPostUser, User } from '../../api/users-api/classes';
import { User_API } from '../../api/users-api/api';

class User_VM {
  count: number = 0;
  data: User[] = [];

  getUsers = async (props: GetReferalsParams) => {
    const res = await User_API.getUsers(props);
    if (res.isSuccess) {
      if (res.data) {
        this.data = res.data.dataList;
        this.count = res.data.count;
      }
    }
  };

  postUser = async (props: BodyPostUser) => {
    const res = await User_API.postUsers(props);
    return res;
  };

  patchUser = async (props: BodyPatchUser) => {
    const res = await User_API.patchUsers(props);
    return res;
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export default User_VM;
