import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Flex, Tooltip, Typography } from 'antd';

import style from './style.module.scss';

import { GrayWrap } from '../../../../../../common/components/GrayWrap';
import { format_usdt_values, getDate } from '../../../../../../common/helpers';
import { InfoElement } from '../common/InfoElement';
import { TimeWaitingInfo } from './components/TimeWaitingInfo';
import { CurrencyType, getCurrency } from './helpers';
import JSONColapse from '../../../JSONColapse';
import { useModulesContext } from '../../../../../../context/ModulesContextProvider';
import { getProvidersDataByKey } from '../../../../../../common/helpers/providers';
import { InfoCircleTwoTone } from '@ant-design/icons';

const View = () => {
  const { id } = useParams<string>(); // useing ti grab data

  const { ExchangesStore } = useModulesContext();

  const {
    changelly_claim,
    provider_name,
    exchange_pair,
    claim_event_list,
    status,
    rate_type,
    frontend_id,
    api_key_id,
    letsexchange_claim,
    amount_expected_from_usdt,
    amount_expected_to_usdt,
    amount_from_usdt,
    amount_to_usdt,
    referral_code,
    api_key,
  } = ExchangesStore.oneClaimData;

  const createdDate = getDate(claim_event_list[0]?.created_at);

  const providerClaimData = changelly_claim ? changelly_claim : letsexchange_claim ? letsexchange_claim : null;

  const providerID = getProvidersDataByKey('providerID', providerClaimData);
  const payInHash = getProvidersDataByKey('payInHash', providerClaimData);
  const payOutHash = getProvidersDataByKey('payOutHash', providerClaimData);

  return (
    <GrayWrap>
      <Flex style={{ flexDirection: 'column' }} gap={20}>
        <Flex className={style.details}>
          <Flex className={style.details__part}>
            <Typography className={style.info_block_title}>Детали заявки</Typography>
            <Flex gap={40}>
              <div className={style.infoDetail_container} style={{ width: 400, padding: '16px 10px 16px 10px' }}>
                <InfoElement title="ID номер" value={id} />
                <InfoElement title="Frontend_id" value={frontend_id} />
                <InfoElement
                  title="Дата создания (local time)"
                  value={`${createdDate?.toLocaleDateString()} ${createdDate.toLocaleTimeString()}`}
                />
                <InfoElement title="api_key_id" value={api_key_id ? api_key_id : '---'} />
              </div>
              <div className={style.infoDetail_container} style={{ width: 400, padding: '16px 10px 16px 10px' }}>
                <InfoElement
                  title="Заявленный курс"
                  value={
                    providerClaimData ? getCurrency(CurrencyType.EXPECTED, providerClaimData, exchange_pair, provider_name) : 'No Data'
                  }
                />
                <InfoElement
                  title="Фактический курс"
                  value={
                    providerClaimData && payInHash !== '' && payOutHash !== ''
                      ? getCurrency(CurrencyType.REAL, providerClaimData, exchange_pair, provider_name)
                      : 'No Data'
                  }
                />
              </div>
              <div className={style.infoDetail_container} style={{ width: 200, padding: '16px 10px 16px 10px' }}>
                <InfoElement
                  title="Заявленный from $"
                  value={amount_expected_from_usdt ? `${format_usdt_values(amount_expected_from_usdt)}$` : 'No Data'}
                />
                <InfoElement
                  title="Заявленный to $"
                  value={amount_expected_to_usdt ? `${format_usdt_values(amount_expected_to_usdt)}$` : 'No Data'}
                />
                <InfoElement title="Фактический from $" value={amount_from_usdt ? `${format_usdt_values(amount_from_usdt)}$` : 'No Data'} />
                <InfoElement title="Фактический to $" value={amount_to_usdt ? `${format_usdt_values(amount_to_usdt)}$` : 'No Data'} />
              </div>
            </Flex>
            <Flex gap={10}>
              <div className={style.infoDetail_container} style={{ width: 600, padding: '16px 10px 16px 10px' }}>
                <Flex className={style.info__external} gap={60}>
                  <Flex gap={20}>
                    <div>
                      <InfoElement title="Имя провайдера" value={provider_name} />
                      <InfoElement title="ID провайдера" value={providerID} />
                    </div>
                    <div>
                      <InfoElement title="Коммисия" value={changelly_claim?.total_fee ? changelly_claim.total_fee : 'No Data'} />
                      <InfoElement title="Коммисия сети" value={changelly_claim?.network_fee ? changelly_claim.network_fee : 'No Data'} />
                    </div>
                    <div>
                      <InfoElement
                        title="Коммисия наша"
                        value={changelly_claim?.api_extra_fee ? `${changelly_claim.api_extra_fee}%` : 'No Data'}
                      />
                      <InfoElement
                        title="Коммисия провайдера"
                        value={changelly_claim?.changelly_fee ? `${changelly_claim.changelly_fee}%` : 'No Data'}
                      />
                    </div>
                  </Flex>
                </Flex>
              </div>
              {api_key && (
                <div className={style.infoDetail_container} style={{ width: 400, padding: '16px 10px 16px 10px' }}>
                  <Flex className={style.info__external} gap={60}>
                    <Flex vertical>
                      <Flex gap={20}>
                        <InfoElement
                          title="api_key_id"
                          element={
                            <Tooltip placement="topLeft" title={api_key.id}>
                              <Flex gap={10}>
                                <Typography style={{ fontWeight: 600, textWrap: 'nowrap' }}>{`${api_key.id.slice(0, 4)}`}</Typography>
                                <InfoCircleTwoTone />
                              </Flex>
                            </Tooltip>
                          }
                        />
                        <InfoElement title="Provider" value={api_key.provider_name} />
                        <InfoElement title="affiliate_id" value={api_key?.affiliate_id ? api_key.affiliate_id : 'no data'} />
                      </Flex>
                      <Flex gap={20}>
                        <InfoElement title="Title" value={api_key.title} />
                        <InfoElement title="commission_percent" value={String(api_key.commission_percent)} />
                      </Flex>
                    </Flex>
                  </Flex>
                </div>
              )}
            </Flex>
            {referral_code && (
              <div className={style.infoDetail_container} style={{ width: 450, padding: '16px 10px 16px 10px' }}>
                <Flex className={style.info__external} gap={60}>
                  <Flex vertical gap={5}>
                    <Flex gap={20}>
                      <InfoElement
                        title="Ref_code_ID"
                        element={
                          <Tooltip placement="topLeft" title={api_key.id}>
                            <Flex gap={10}>
                              <Typography style={{ fontWeight: 600, textWrap: 'nowrap' }}>{`${referral_code.id.slice(0, 4)}`}</Typography>
                              <InfoCircleTwoTone />
                            </Flex>
                          </Tooltip>
                        }
                      />
                      <InfoElement title="Ref_code_status" value={referral_code.is_active ? 'ACTIVE' : 'UNACTIVE'} />
                      <InfoElement title="swap_accumulation_percent" value={String(referral_code.swap_accumulation_percent)} />
                    </Flex>
                    <Flex gap={20}>
                      <InfoElement title="Title" value={referral_code.title} />
                      <InfoElement title="Code_value" value={referral_code.code_value} />
                      <InfoElement title="swap_discount_percent" value={String(referral_code.swap_discount_percent)} />
                    </Flex>
                  </Flex>
                </Flex>
              </div>
            )}
            <div className={style.infoDetail_container} style={{ width: 500, padding: '16px 10px 16px 10px' }}>
              <Flex gap={20}>
                <InfoElement title="Статус заявки" value={status} />
                <TimeWaitingInfo eventList={claim_event_list} />
                <InfoElement title="Тип курса обмена" value={rate_type} />
              </Flex>
            </div>
            {/* <div className={style.info__darknet}>
              <Typography>{`Darknet marketplace: ${'xx%'}`}</Typography>
            </div> */}
          </Flex>
        </Flex>
        <JSONColapse response={ExchangesStore.responseGetOneClaimDataField} />
      </Flex>
    </GrayWrap>
  );
};

export const ExDetails = observer(View);
