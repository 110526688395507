import { makeAutoObservable } from 'mobx';
import { APIKEYS_API } from '../../api/apikeys-api/api';
import { API_KEY, BodyPatchAPIKeys, BodyPostAPIKeys, getAPI_KEYS_DataParams } from '../../api/apikeys-api/classes';

class API_KEYS_VM {
  count: number = 0;
  data: API_KEY[] = [];

  getAPIKeysList = async (props: getAPI_KEYS_DataParams) => {
    const res = await APIKEYS_API.getAPIKeys(props);
    if (res.isSuccess) {
      if (res.data) {
        this.data = res.data.dataList;
        this.count = res.data.count;
      }
    }
  };

  postAPIKey = async (props: BodyPostAPIKeys) => {
    const res = await APIKEYS_API.postAPIKey(props);
    if (res.isSuccess) {
      if (res.data) {
        this.data = res.data.dataList;
        this.count = res.data.count;
      }
    }
  };

  patchAPIKey = async (props: BodyPatchAPIKeys) => {   
    const res = await APIKEYS_API.patchAPIKeys(props);
    if (res.isSuccess) {
      if (res.data) {
        this.data = res.data.dataList;
        this.count = res.data.count;
      }
    }
  };

  // toggleSettingKey = async (props: ToggleSettingsProps) => {
  //   const res = await generalSettingsAPI.toggleSettingKey(props);
  //   if (res.isSuccess) {
  //     this.getSettingsList();
  //   } else {
  //     toast.error(`Не получилось обновить состояние свойства с id: ${props.id}`);
  //   }
  // };

  constructor() {
    makeAutoObservable(this);
  }
}

export default API_KEYS_VM;
