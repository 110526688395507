import { useEffect } from 'react';
import { FormInstance, Input, Form } from 'antd';
import { BodyPostUser } from '../../../../api/users-api/classes';

interface Props {
  onFormInstanceReady: (instance: FormInstance<BodyPostUser>) => void;
}

const CreateForm: React.FC<Props> = ({ onFormInstanceReady }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    onFormInstanceReady(form);
  }, [form, onFormInstanceReady]);

  return (
    <Form layout="vertical" form={form} name="form_in_modal">
      <Form.Item name="login" label="Логин" rules={[{ required: true, message: 'Введите логин' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Введите email' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="password" label="Pass" rules={[{ required: true, message: 'Введите pass' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        name="swap_discount_percent_extra"
        label="swap_discount_percent_extra"
        rules={[{ required: true, message: 'Укажите swap_discount_percent_extra' }]}
      >
        <Input type="number" />
      </Form.Item>
    </Form>
  );
};

export default CreateForm;
