import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Flex, FloatButton, Form, Input, Layout, Popconfirm, Table, TableColumnsType, Tooltip, Typography, Switch } from 'antd';
import { Header, Content } from 'antd/es/layout/layout';

import style from './styles/style.module.scss';

import { Loader } from '../../common/components/Loader';
import { useModulesContext } from '../../context/ModulesContextProvider';
import { InfoCircleTwoTone, PlusCircleOutlined } from '@ant-design/icons';
import CreateFormModal from './createModal';
import { getDate } from '../../common/helpers';
import { SearchField } from '../../common/components/SearchField';
import { BodyPatchReferals, ReferalCode } from '../../api/referals_api/classes';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  index: number;
}

// todo remove from external
const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({ editing, dataIndex, children, title, ...restProps }) => {
  const getInputType = (columnName: keyof ReferalCode) => {
    switch (columnName) {
      case 'is_active': {
        return <Switch checkedChildren="ВКЛ" unCheckedChildren="ВЫКЛ" />;
      }
      default: {
        return (
          <>
            <Input type={dataIndex === 'swap_discount_percent' || dataIndex === 'swap_accumulation_percent' ? 'number' : 'text'} />
          </>
        );
      }
    }
  };

  return (
    <td width={1} {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex} // field to match form value and data value
          style={{ margin: 0, width: '200px' }}
          rules={[
            {
              required: dataIndex === 'affiliate_id' ? false : true,
              message: `Please Input`,
            },
          ]}
        >
          {getInputType(dataIndex as keyof ReferalCode)}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const View = () => {
  const { ReferalsStore } = useModulesContext();
  const { data, getReferalCodes, postCode, patchReferalCode } = ReferalsStore;

  const [pageData, setPageData] = useState({
    page: 1,
    perPage: 100,
  });
  const [searchValue, setSearchValue] = useState('');
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    setLoading(true);
    const props = {
      ...pageData,
      searchValue: searchValue ? searchValue : undefined,
    };
    getReferalCodes(props).then(() => setLoading(false));
  }, [getReferalCodes, pageData, searchValue]);

  const [editingKey, setEditingKey] = useState<string>();

  const cancel = () => {
    setEditingKey('');
  };

  const edit = (record: Partial<ReferalCode>) => {
    form.setFieldsValue({ ...record }); // matching form values & record values
    setEditingKey(record.id);
  };

  const save = async (record: ReferalCode) => {
    const patchProps: BodyPatchReferals = {
      referral_code_id: record.id,
      title: record.title,
      is_active: record.is_active,
      code_value: record.code_value,
      swap_accumulation_percent: record.swap_accumulation_percent,
      swap_discount_percent: record.swap_discount_percent,
    };
    await patchReferalCode(patchProps);
    await getReferalCodes({ ...pageData });
    setEditingKey('');
  };

  const isEditing = (record: ReferalCode) => record.id === editingKey;

  const columns = (): (TableColumnsType<ReferalCode>[number] & { editable?: boolean })[] => [
    {
      title: 'ID',
      render: (_: any, record) => (
        <>
          <Tooltip placement="topLeft" title={record.id}>
            <Flex gap={10}>
              <Typography style={{ fontWeight: 600, textWrap: 'nowrap' }}>{`${record.id.slice(0, 4)}`}</Typography>
              <InfoCircleTwoTone />
            </Flex>
          </Tooltip>
        </>
      ),
    },
    {
      title: 'title',
      editable: true,
      render: (_: any, record) => (
        <>
          <Flex>
            <Typography style={{ marginLeft: '10px', textWrap: 'nowrap' }}>{record.title}</Typography>
          </Flex>
        </>
      ),
    },
    {
      title: 'code_value',
      editable: true,
      render: (_: any, record) => (
        <>
          <Flex>
            <Typography style={{ marginLeft: '10px', textWrap: 'nowrap' }}>{record?.code_value}</Typography>
          </Flex>
        </>
      ),
    },
    {
      title: 'swap_discount_percent',
      editable: true,
      render: (_: any, record) => (
        <>
          <Flex>
            <Typography style={{ marginLeft: '10px', textWrap: 'nowrap' }}>{record?.swap_discount_percent}</Typography>
          </Flex>
        </>
      ),
    },
    {
      title: 'swap_accumulation_percent',
      editable: true,
      render: (_: any, record) => {
        return (
          <>
            <Flex>
              <Typography style={{ marginLeft: '10px', textWrap: 'nowrap' }}>{record?.swap_accumulation_percent}</Typography>
            </Flex>
          </>
        );
      },
    },
    {
      title: 'is_active',
      editable: true,
      render: (_: any, record) => (
        <>
          <Switch checkedChildren="ВКЛ" unCheckedChildren="ВЫКЛ" disabled={true} checked={record.is_active} />
        </>
      ),
    },
    {
      title: 'created_at',
      render: (_: any, record) => {
        const date = getDate(record.created_at);
        return (
          <>
            <Flex>
              <Typography
                style={{ marginLeft: '10px', textWrap: 'nowrap' }}
              >{`${date.toLocaleDateString()} ${date.toLocaleTimeString()}`}</Typography>
            </Flex>
          </>
        );
      },
    },
    {
      title: 'operation',
      render: (_: any, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => {
                save({ ...record, ...form.getFieldsValue() });
              }}
              style={{ marginInlineEnd: 8, textWrap: 'nowrap' }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <Typography.Link style={{ textWrap: 'nowrap' }}>Cancel</Typography.Link>
            </Popconfirm>
          </span>
        ) : (
          <Button onClick={() => edit(record)}>Edit value</Button>
        );
      },
    },
  ];

  const mergedColumns = columns().map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: ReferalCode) => ({
        editing: isEditing(record), // custom field for cell
        dataIndex: col.title,
      }),
    };
  });

  return (
    <>
      <CreateFormModal
        open={isCreateModalOpen}
        onCancel={() => setIsCreateModalOpen(false)}
        onCreate={async (form_values) => {
          await postCode(form_values);
          await getReferalCodes({ ...pageData });
          setIsCreateModalOpen(false);
        }}
      />
      <Layout className={style.layout}>
        <Header className={style.layout__header_api_keys}>
          <Typography className={style.module_header}>Реферальные коды</Typography>
          <FloatButton
            style={{ top: 10, right: 10 }}
            icon={<PlusCircleOutlined style={{ fontSize: '20px' }} />}
            onClick={() => setIsCreateModalOpen(true)}
          />
        </Header>
        <Content className={style.layout__content}>
          <Flex gap={10} vertical>
            <SearchField searchValue={searchValue} setSearchValue={setSearchValue} fieldName="Search" width={300} />
            {loading ? (
              <Loader />
            ) : (
              <Form form={form} component={false}>
                <Table<ReferalCode>
                  rowKey="id"
                  dataSource={data}
                  scroll={{ x: 1100 }}
                  columns={mergedColumns as TableColumnsType<ReferalCode>}
                  bordered
                  components={{
                    body: { cell: EditableCell },
                  }}
                  pagination={false}
                />
              </Form>
            )}
          </Flex>
        </Content>
      </Layout>
    </>
  );
};

export const ReferalCodes = observer(View);
