import { FormInstance, Input, Form, Select } from 'antd';
import { useEffect } from 'react';
import { EnumProviders } from '../../../../common/types';
import { BodyPostAPIKeys } from '../../../../api/apikeys-api/classes';
import TextArea from 'antd/es/input/TextArea';

interface Props {
  onFormInstanceReady: (instance: FormInstance<BodyPostAPIKeys>) => void;
}

const CreateForm: React.FC<Props> = ({ onFormInstanceReady }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    onFormInstanceReady(form);
  }, [form, onFormInstanceReady]);

  return (
    <Form layout="vertical" form={form} name="form_in_modal">
      <Form.Item name="provider_name" label="Провайдер" rules={[{ required: true, message: 'Выберете название провайдера' }]}>
        <Select>
          <Select.Option value={EnumProviders.CHANGELLY}>CHANGELLY</Select.Option>
          <Select.Option value={EnumProviders.LETS_EXCHANGE}>LETS_EXCHANGE</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="title" label="Название" rules={[{ required: true, message: 'Введите название' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="commission_percent" label="Процент" rules={[{ required: true, message: 'Укажите процент комиссии' }]}>
        <Input type="number" />
      </Form.Item>
      <Form.Item name="private_key" label="private_key" rules={[{ required: true, message: 'Укажите private_key' }]}>
        <TextArea />
      </Form.Item>
      <Form.Item name="affiliate_id" label="affiliate_id" rules={[{ required: false, message: 'Укажите affiliate_id' }]}>
        <Input />
      </Form.Item>
    </Form>
  );
};

export default CreateForm;
