import { API } from '..';
import { getToken } from '../../context/AuthContextProvider';
import { BodyPatchUser, BodyPostUser, GetUsersParams, GetUsersRes } from './classes';

const PATHS = {
  USERS: '/api/users',
};

export const User_API = {
  getUsers: async (props: GetUsersParams) => {
    const token = getToken();
    return await API.get<GetUsersRes>({
      url: PATHS.USERS,
      params: { ...props },
      headers: {
        'x-access-token': token,
      },
    });
  },
  postUsers: async (props: BodyPostUser) => {
    const withEditedDataType: BodyPostUser = {
      ...props,
      swap_discount_percent_extra: Number(props.swap_discount_percent_extra),
    };
    const token = getToken();
    return await API.post({
      url: PATHS.USERS,
      data: { ...withEditedDataType },
      headers: {
        'x-access-token': token,
      },
    });
  },
  patchUsers: async (props: BodyPatchUser) => {
    const withEditedDataType: BodyPatchUser = {
      ...props,
      swap_discount_percent_extra: props?.swap_discount_percent_extra ? Number(props.swap_discount_percent_extra) : undefined,
    };
    const token = getToken();
    return await API.patch({
      url: PATHS.USERS,
      data: { ...withEditedDataType },
      headers: {
        'x-access-token': token,
      },
    });
  },
};
