import { API } from '..';
import { getToken } from '../../context/AuthContextProvider';
import { BodyPatchReferals, BodyPostReferals, GetReferalsParams, GetReferalsResponse } from './classes';

const PATHS = {
  REFERALS: '/api/referral-codes',
};

export const Referals_API = {
  getReferals: async (props: GetReferalsParams) => {
    const token = getToken();
    return await API.get<GetReferalsResponse>({
      url: PATHS.REFERALS,
      params: { ...props },
      headers: {
        'x-access-token': token,
      },
    });
  },

  postReferalCode: async (props: BodyPostReferals) => {
    const withEditedDataType: BodyPostReferals = {
      ...props,
      swap_discount_percent: Number(props.swap_discount_percent),
      swap_accumulation_percent: Number(props.swap_accumulation_percent),
    };
    const token = getToken();
    return await API.post({
      url: PATHS.REFERALS,
      data: { ...withEditedDataType },
      headers: {
        'x-access-token': token,
      },
    });
  },

  patchReferals: async (props: BodyPatchReferals) => {
    const withEditedDataType: BodyPatchReferals = {
      ...props,
      swap_discount_percent: Number(props.swap_discount_percent),
      swap_accumulation_percent: Number(props.swap_accumulation_percent),
    };
    const token = getToken();
    return await API.patch({
      url: PATHS.REFERALS,
      data: { ...withEditedDataType },
      headers: {
        'x-access-token': token,
      },
    });
  },
};
