import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Flex, FloatButton, Form, Input, Layout, Popconfirm, Table, TableColumnsType, Tooltip, Typography } from 'antd';
import { Header, Content } from 'antd/es/layout/layout';

import style from './styles/style.module.scss';

import { Loader } from '../../common/components/Loader';
import { useModulesContext } from '../../context/ModulesContextProvider';
import { InfoCircleTwoTone, PlusCircleOutlined } from '@ant-design/icons';
import CreateFormModal from './createModal';
import { getDate } from '../../common/helpers';
import { SearchField } from '../../common/components/SearchField';
import { BodyPatchUser, User } from '../../api/users-api/classes';
import { toast } from 'react-toastify';
import ChangePassModal from './changePassModal';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  index: number;
}

// todo remove from external
const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({ editing, dataIndex, children, title, ...restProps }) => {
  const getInputType = (columnName: keyof User) => {
    switch (columnName) {
      // case 'is_active': {
      //   return <Switch checkedChildren="ВКЛ" unCheckedChildren="ВЫКЛ" />;
      // }
      default: {
        return (
          <>
            <Input type={dataIndex === 'swap_discount_percent' || dataIndex === 'swap_accumulation_percent' ? 'number' : 'text'} />
          </>
        );
      }
    }
  };

  return (
    <td width={1} {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex} // field to match form value and data value
          style={{ margin: 0, width: '200px' }}
          rules={[
            {
              required: dataIndex === 'affiliate_id' ? false : true,
              message: `Please Input`,
            },
          ]}
        >
          {getInputType(dataIndex as keyof User)}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const View = () => {
  const { UsersStore } = useModulesContext();
  const { data, getUsers, postUser, patchUser } = UsersStore;

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isChangePassModalOpen, setIsChangePassModalOpen] = useState<boolean>(false);
  const [pageData, setPageData] = useState({
    page: 1,
    perPage: 100,
  });
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [changedPassProfile, setChangedPassProfile] = useState<{ user_id: string; email: string } | undefined>();

  const [form] = Form.useForm();

  useEffect(() => {
    setLoading(true);
    const props = {
      ...pageData,
      searchValue: searchValue ? searchValue : undefined,
    };
    getUsers(props).then(() => setLoading(false));
  }, [getUsers, pageData, searchValue]);

  const [editingKey, setEditingKey] = useState<string>();

  const cancel = () => {
    setEditingKey('');
  };

  const edit = (record: Partial<User>) => {
    form.setFieldsValue({ ...record }); // matching form values & record values
    setEditingKey(record.id);
  };

  const save = async (record: User) => {
    const patchProps: BodyPatchUser = {
      user_id: record.id,
      login: record.login,
      email: record.email,
      swap_discount_percent_extra: record.swap_discount_percent_extra,
    };
    console.log('patchProps', patchProps);
    const res = await patchUser(patchProps);
    if (res.isSuccess) {
      setEditingKey('');
      await getUsers({ ...pageData });
    } else {
      toast.error(res.message);
    }
  };

  const isEditing = (record: User) => record.id === editingKey;

  const columns = (): (TableColumnsType<User>[number] & { editable?: boolean })[] => [
    {
      title: 'ID',
      render: (_: any, record) => (
        <>
          <Tooltip placement="topLeft" title={record.id}>
            <Flex gap={10}>
              <Typography style={{ fontWeight: 600, textWrap: 'nowrap' }}>{`${record.id.slice(0, 4)}`}</Typography>
              <InfoCircleTwoTone />
            </Flex>
          </Tooltip>
        </>
      ),
    },
    {
      title: 'login',
      editable: true,
      render: (_: any, record) => (
        <>
          <Flex>
            <Typography style={{ marginLeft: '10px', textWrap: 'nowrap' }}>{record.login}</Typography>
          </Flex>
        </>
      ),
    },
    {
      title: 'email',
      editable: true,
      render: (_: any, record) => (
        <>
          <Flex>
            <Typography style={{ marginLeft: '10px', textWrap: 'nowrap' }}>{record.email}</Typography>
          </Flex>
        </>
      ),
    },
    {
      title: 'balance_usdt',
      render: (_: any, record) => (
        <>
          <Flex>
            <Typography style={{ marginLeft: '10px', textWrap: 'nowrap' }}>{record?.balance_usdt}</Typography>
          </Flex>
        </>
      ),
    },
    {
      title: 'swap_discount_percent',
      render: (_: any, record) => {
        return (
          <>
            <Flex>
              <Typography style={{ marginLeft: '10px', textWrap: 'nowrap' }}>{record?.swap_discount_percent}</Typography>
            </Flex>
          </>
        );
      },
    },
    {
      title: 'swap_discount_percent_extra',
      editable: true,
      render: (_: any, record) => {
        return (
          <>
            <Flex>
              <Typography style={{ marginLeft: '10px', textWrap: 'nowrap' }}>{record?.swap_discount_percent_extra}</Typography>
            </Flex>
          </>
        );
      },
    },
    {
      title: 'operaton',
      render: (_: any, record) => {
        return (
          <>
            <Flex>
              <Button
                onClick={() => {
                  setChangedPassProfile({ user_id: record.id, email: record.email });
                  setIsChangePassModalOpen(true);
                }}
              >
                Change pass
              </Button>
            </Flex>
          </>
        );
      },
    },
    {
      title: 'created_at',
      render: (_: any, record) => {
        const date = getDate(record.created_at);
        return (
          <>
            <Flex>
              <Typography
                style={{ marginLeft: '10px', textWrap: 'nowrap' }}
              >{`${date.toLocaleDateString()} ${date.toLocaleTimeString()}`}</Typography>
            </Flex>
          </>
        );
      },
    },
    {
      title: 'operation',
      render: (_: any, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => {
                save({ ...record, ...form.getFieldsValue() });
              }}
              style={{ marginInlineEnd: 8, textWrap: 'nowrap' }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <Typography.Link style={{ textWrap: 'nowrap' }}>Cancel</Typography.Link>
            </Popconfirm>
          </span>
        ) : (
          <Button onClick={() => edit(record)}>Edit value</Button>
        );
      },
    },
  ];

  const mergedColumns = columns().map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: User) => ({
        editing: isEditing(record), // custom field for cell
        dataIndex: col.title,
      }),
    };
  });

  return (
    <>
      <ChangePassModal
        open={isChangePassModalOpen && Boolean(changedPassProfile)}
        onCancel={() => setIsChangePassModalOpen(false)}
        onCreate={async (form_values) => {
          const props: BodyPatchUser = { user_id: form_values.user_id, password: form_values.password };
          patchUser(props);
          setIsChangePassModalOpen(false);
        }}
        user_id={changedPassProfile?.user_id ? changedPassProfile.user_id : ''}
        user_email={changedPassProfile?.email ? changedPassProfile.email : ''}
      />
      <CreateFormModal
        open={isCreateModalOpen}
        onCancel={() => setIsCreateModalOpen(false)}
        onCreate={async (form_values) => {
          const res = await postUser(form_values);
          if (res.isSuccess) {
            setIsCreateModalOpen(false);
            toast.success('User successfully added');
            getUsers({ ...pageData });
          } else {
            toast.success(res.message);
          }
        }}
      />
      <Layout className={style.layout}>
        <Header className={style.layout__header_api_keys}>
          <Typography className={style.module_header}>Пользователи</Typography>
          <FloatButton
            style={{ top: 10, right: 10 }}
            icon={<PlusCircleOutlined style={{ fontSize: '20px' }} />}
            onClick={() => setIsCreateModalOpen(true)}
          />
        </Header>
        <Content className={style.layout__content}>
          <Flex gap={10} vertical>
            <SearchField searchValue={searchValue} setSearchValue={setSearchValue} fieldName="Search" width={300} />
            {loading ? (
              <Loader />
            ) : (
              <Form form={form} component={false}>
                <Table<User>
                  rowKey="id"
                  dataSource={data}
                  scroll={{ x: 1100 }}
                  columns={mergedColumns as TableColumnsType<User>}
                  bordered
                  components={{
                    body: { cell: EditableCell },
                  }}
                  pagination={false}
                />
              </Form>
            )}
          </Flex>
        </Content>
      </Layout>
    </>
  );
};

export const Users = observer(View);
