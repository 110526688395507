import { useEffect } from 'react';
import { FormInstance, Input, Form } from 'antd';
import { BodyPostReferals } from '../../../../api/referals_api/classes';

interface Props {
  onFormInstanceReady: (instance: FormInstance<BodyPostReferals>) => void;
}

const CreateForm: React.FC<Props> = ({ onFormInstanceReady }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    onFormInstanceReady(form);
  }, [form, onFormInstanceReady]);

  return (
    <Form layout="vertical" form={form} name="form_in_modal">
      <Form.Item name="title" label="Название" rules={[{ required: true, message: 'Введите название' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="code_value" label="Cекретный код" rules={[{ required: true, message: 'Укажите cекретный код' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        name="swap_discount_percent"
        label="swap_discount_percent"
        rules={[{ required: true, message: 'Укажите swap_discount_percent' }]}
      >
        <Input type="number" />
      </Form.Item>
      <Form.Item
        name="swap_accumulation_percent"
        label="swap_accumulation_percent"
        rules={[{ required: true, message: 'Укажите swap_accumulation_percent' }]}
      >
        <Input type="number" />
      </Form.Item>
    </Form>
  );
};

export default CreateForm;
