import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Flex, FloatButton, Form, Input, Layout, Popconfirm, Select, Table, TableColumnsType, Tooltip, Typography } from 'antd';
import { Header, Content } from 'antd/es/layout/layout';

import style from './styles/style.module.scss';

import { Loader } from '../../common/components/Loader';
import { useModulesContext } from '../../context/ModulesContextProvider';
import { API_KEY } from '../../api/apikeys-api/classes';
import { BorderlessTableOutlined, InfoCircleTwoTone, PlusCircleOutlined } from '@ant-design/icons';
import CreateFormModal from './createModal';
import { getDate } from '../../common/helpers';
import { EnumProviders } from '../../common/types';
import TextArea from 'antd/es/input/TextArea';
import { SearchField } from '../../common/components/SearchField';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  index: number;
}

// todo remove from external
const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({ editing, dataIndex, children, title, ...restProps }) => {
  const getInputType = (columnName: keyof API_KEY) => {
    switch (columnName) {
      case 'provider_name': {
        return (
          <>
            <Select>
              <Select.Option value={EnumProviders.CHANGELLY}>CHANGELLY</Select.Option>
              <Select.Option value={EnumProviders.LETS_EXCHANGE}>LETS_EXCHANGE</Select.Option>
            </Select>
          </>
        );
      }
      case 'private_key': {
        return <TextArea />;
      }
      default: {
        return (
          <>
            <Input type={dataIndex === 'commission_percent' ? 'number' : 'text'} />
          </>
        );
      }
    }
  };

  return (
    <td width={1} {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex} // field to match form value and data value
          style={{ margin: 0, width: '200px' }}
          rules={[
            {
              required: dataIndex === 'affiliate_id' ? false : true,
              message: `Please Input`,
            },
          ]}
        >
          {getInputType(dataIndex as keyof API_KEY)}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const View = () => {
  const { API_KeysStore } = useModulesContext();
  const { data, getAPIKeysList, postAPIKey, patchAPIKey } = API_KeysStore;

  const [pageData, setPageData] = useState({
    page: 1,
    perPage: 100,
  });
  const [searchValue, setSearchValue] = useState('');
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    setLoading(true);
    const props = {
      ...pageData,
      searchValue: searchValue ? searchValue : undefined,
    };
    getAPIKeysList(props).then(() => setLoading(false));
  }, [getAPIKeysList, pageData, searchValue]);

  const [editingKey, setEditingKey] = useState<string>();

  const cancel = () => {
    setEditingKey('');
  };

  const edit = (record: Partial<API_KEY>) => {
    form.setFieldsValue({ ...record }); // matching form values & record values
    setEditingKey(record.id);
  };

  const save = async (record: API_KEY) => {
    await patchAPIKey({
      api_key_id: record.id,
      provider_name: record.provider_name,
      title: record.title,
      commission_percent: record.commission_percent,
      private_key: record.private_key,
      affiliate_id: record.affiliate_id,
    });
    await getAPIKeysList({ ...pageData });
    setEditingKey('');
  };

  const isEditing = (record: API_KEY) => record.id === editingKey;

  const columns = (): (TableColumnsType<API_KEY>[number] & { editable?: boolean })[] => [
    {
      title: 'ID',
      render: (_: any, record) => (
        <>
          <Tooltip placement="topLeft" title={record.id}>
            <Flex gap={10}>
              <Typography style={{ fontWeight: 600, textWrap: 'nowrap' }}>{`${record.id.slice(0, 4)}`}</Typography>
              <InfoCircleTwoTone />
            </Flex>
          </Tooltip>
        </>
      ),
    },
    {
      title: 'provider_name',
      editable: true,
      render: (_: any, record) => (
        <>
          <Flex>
            <Typography style={{ marginLeft: '10px', textWrap: 'nowrap' }}>{record.provider_name}</Typography>
          </Flex>
        </>
      ),
    },
    {
      title: 'title',
      editable: true,
      render: (_: any, record) => (
        <>
          <Flex>
            <Typography style={{ marginLeft: '10px', textWrap: 'nowrap' }}>{record.title}</Typography>
          </Flex>
        </>
      ),
    },
    {
      title: 'commission_percent',
      editable: true,
      render: (_: any, record) => (
        <>
          <Flex>
            <Typography style={{ marginLeft: '10px', textWrap: 'nowrap' }}>{record.commission_percent}</Typography>
          </Flex>
        </>
      ),
    },
    {
      title: 'private_key',
      editable: true,
      render: (_: any, record) => {
        return (
          <>
            <Tooltip overlayInnerStyle={{ width: '500px' }} placement="topLeft" title={record.private_key}>
              <Flex gap={10}>
                <BorderlessTableOutlined />
                <BorderlessTableOutlined />
                <BorderlessTableOutlined />
              </Flex>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: 'affiliate_id',
      editable: true,
      render: (_: any, record) => (
        <>
          <Flex>
            <Typography style={{ marginLeft: '10px', textWrap: 'nowrap' }}>{record.affiliate_id}</Typography>
          </Flex>
        </>
      ),
    },
    {
      title: 'created_at',
      render: (_: any, record) => {
        const date = getDate(record.created_at);
        return (
          <>
            <Flex>
              <Typography
                style={{ marginLeft: '10px', textWrap: 'nowrap' }}
              >{`${date.toLocaleDateString()} ${date.toLocaleTimeString()}`}</Typography>
            </Flex>
          </>
        );
      },
    },
    {
      title: 'operation',
      render: (_: any, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => {
                save({ ...record, ...form.getFieldsValue() });
              }}
              style={{ marginInlineEnd: 8, textWrap: 'nowrap' }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <Typography.Link style={{ textWrap: 'nowrap' }}>Cancel</Typography.Link>
            </Popconfirm>
          </span>
        ) : (
          <Button onClick={() => edit(record)}>Edit value</Button>
        );
      },
    },
  ];

  const mergedColumns = columns().map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: API_KEY) => ({
        editing: isEditing(record), // custom field for cell
        dataIndex: col.title,
      }),
    };
  });

  return (
    <>
      <CreateFormModal
        open={isCreateModalOpen}
        onCancel={() => setIsCreateModalOpen(false)}
        onCreate={async (form_values) => {
          await postAPIKey(form_values);
          await getAPIKeysList({ ...pageData });
          setIsCreateModalOpen(false);
        }}
      />
      <Layout className={style.layout}>
        <Header className={style.layout__header_api_keys}>
          <Typography className={style.module_header}>API Ключи</Typography>
          <FloatButton
            style={{ top: 10, right: 10 }}
            icon={<PlusCircleOutlined style={{ fontSize: '20px' }} />}
            onClick={() => setIsCreateModalOpen(true)}
          />
        </Header>
        <Content className={style.layout__content}>
          <Flex gap={10} vertical>
            <SearchField searchValue={searchValue} setSearchValue={setSearchValue} fieldName="Search" />
            {loading ? (
              <Loader />
            ) : (
              <Form form={form} component={false}>
                <Table<API_KEY>
                  rowKey="id"
                  dataSource={data}
                  scroll={{ x: 1100 }}
                  columns={mergedColumns as TableColumnsType<API_KEY>}
                  bordered
                  components={{
                    body: { cell: EditableCell },
                  }}
                  pagination={false}
                />
              </Form>
            )}
          </Flex>
        </Content>
      </Layout>
    </>
  );
};

export const API_KEYS = observer(View);
