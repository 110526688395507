import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Content, Header } from 'antd/es/layout/layout';
import { Button, Flex, Layout, Table, Typography } from 'antd';

import style from './styles/style.module.scss';

import { columns } from './columns';
import InfoModal from './components/infoModal';
import { FilterBar } from './components/FilterBar';
import { useModulesContext } from '../../context/ModulesContextProvider';
import { useLocation } from 'react-router-dom';
import { ActionValueTypes, IActionTypes } from './vm';
import { SelectStaticFilter } from '../../common/components/SelectStaticFilter';
import { LoaderSmall } from '../../common/components/LoaderSmall';
import { useDataRequest } from '../../hooks/useIntervalDataFetch';
import { intervalsData } from '../../common/types';
import { ClearingFiltersType } from '../clearing/vm';

export type TImportedFilters = {
  filters: ClearingFiltersType;
};

const View = () => {
  //state
  const {
    ExchangesStore: {
      getExchanges,
      exchanges,
      count,
      isFetchingData,
      lastTimeUpdate,
      selectFilters: editFilters,
      filters,
      pageInfo,
      setPageInfo,
    },
  } = useModulesContext();

  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [refetchInterval, setRefetchInterval] = useState<number | null>(5000);
  const locationState = useLocation().state as TImportedFilters | null;
  // effects
  useEffect(() => {
    getExchanges();
  }, [getExchanges]);

  useDataRequest(() => getExchanges(), refetchInterval);

  useEffect(() => {
    if (locationState) {
      const clearingfilters = locationState.filters;
      editFilters({
        type: 'fromClearing',
        value: { clearingFilters: clearingfilters },
      });
    }
  }, [editFilters, locationState]);

  // handlers
  function handleFilter(value: ActionValueTypes, filterType: IActionTypes) {
    editFilters({
      type: filterType,
      value: value,
    });
  }

  function handleRefetchIntervalSelect(value: string) {
    if (value === '0') {
      setRefetchInterval(null);
    } else {
      setRefetchInterval(Number(value));
    }
  }

  return (
    <Layout className={style.layout}>
      <Header className={style.layout__header_exchanges}>
        <Flex className={style.header_content}>
          <Typography className={style.module_header}>Обмены</Typography>
          <Flex className={style.header_content_selectInfo}>
            <SelectStaticFilter
              value={refetchInterval ? `${refetchInterval / 1000} сек` : 'Off'}
              width="100px"
              options={intervalsData}
              onSelect={handleRefetchIntervalSelect}
            />
            <Flex className={style.updateInfo}>
              {isFetchingData ? (
                <LoaderSmall />
              ) : (
                <Typography
                  style={{ color: 'gray', textWrap: 'nowrap' }}
                >{`Last update: ${lastTimeUpdate}`}</Typography>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Button type="primary" className={style.instrutionButton} onClick={() => setOpenInfoModal(true)}>
          Показать инструкцию
        </Button>
      </Header>

      <Content className={style.layout__content_exchanges}>
        <FilterBar filters={filters} onFilter={handleFilter} />
        <InfoModal open={openInfoModal} onCancel={() => setOpenInfoModal(false)} />
        <Table
          rowKey="id"
          scroll={{ x: 1500 }}
          dataSource={exchanges}
          columns={columns()}
          bordered={true}
          pagination={{
            total: count,
            showSizeChanger: true,
            pageSize: pageInfo.perPage,
            onChange(page, pageSize) {
              setPageInfo({
                page: page,
                perPage: pageSize,
              });
            },
          }}
        />
      </Content>
    </Layout>
  );
};

export const Exchanges = observer(View);
